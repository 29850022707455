import React from 'react';
import { Link } from 'react-router-dom';
import Title from 'components/Atoms/Title/Title';

const LitterElement = ({ id, title, birth, gallery }) => {
console.log(birth)
    return(
        <Link className="block w-80 transition-transform ease-out mx-3.5 my-3 no-underline hover:scale-110" to={{
            pathname: `litters/${id}`,
        }}>
            <img className="w-1/1 h-40 mx-auto my-0 no-underline rounded-2xl hover:rotate-3 transition:all duration-75 transform" src={gallery ? gallery.filter((e) => e.category === "mainImg").map((item) => item.pathurl) : ""} title={title} alt={title}/>
            <div>
                <Title size="23px">
                    {title} ✦ {birth.slice(0,10)}
                </Title>
            </div>
        </Link>
    );
};

export default LitterElement;