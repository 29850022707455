import Title from 'components/Atoms/Title/Title';
import React from 'react';
import Modal from 'components/Molecules/Modal/Modal';
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import axios from 'axios';
import Loading from 'components/Atoms/Loading/Loading';
import cookies from 'react-cookies';
import ParentElement from '../../components/Organisms/ParentElement/ParentElement';

export default class LitterDetailsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            isLoading: false,
            error: null,
            items: [],
        };
    }

    componentDidMount() {
        const id = window.location.pathname.slice(9);
        axios
            .get(`https://api.shadowdog.eu/v1/litters/${id}`)
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { isLoading, items, error } = this.state;
        const language = cookies.load('language');

        if (error) {
            return <span>Error: {error}</span>;
        } else if (!isLoading) {
            return <Loading />;
        } else {
            return (
                <>
                    <Title>
                        {language === 'pl' ? items.pl_title : items.en_title}
                    </Title>
                    <Title size='30px'>
                        {language === 'pl' ? 'Data urodzenia: ' : 'Birth Date:'}
                        {items.birthDate}
                    </Title>

                    {/* MAIN IMG */}
                    <div className='w-3/4 flex justify-center items-center mx-auto'>
                        <img
                            src={items.gallery
                                .filter((e) => e.category === 'parentImg')
                                .map((item) => item.pathurl)}
                            alt=''
                            className='w-3/4 h-auto rounded-xl'
                        />
                    </div>

                    {/* PARENTS AND CARDS IMAGES */}
                    <div className='flex flex-col xl:flex-row justify-center items-center w-3/4 mx-auto my-5'>
                        <div className='w-2/5 flex flex-col mr-8'>
                            <ParentElement id={items.mother_ID} />
                            <ParentElement id={items.father_ID} />
                        </div>

                        <div className='w-3/5 ml-8'>
                            <LightgalleryProvider>

                                    <div className='flex flex-col xl:flex-row w-1/1 justify-center items-center text-center'>
                                        {items.gallery
                                            .filter(
                                                (e) => e.category === 'cardsImg'
                                            )
                                            .map((item) => (
                                                <LightgalleryItem src={item.pathurl}>
                                                    <div className='m-2 cursor-pointer'>

                                                            <img
                                                                src={item.pathurl}
                                                                alt=''
                                                                className='w-auto h-80'
                                                            />

                                                    </div>
                                                </LightgalleryItem>
                                            ))}
                                    </div>
                            </LightgalleryProvider>
                        </div>
                    </div>

                    <div className='w-3/4 mx-auto'>
                        <Title align='left' size='30px'>
                            {language === 'pl' ? 'Szczeniaki' : 'Puppies'}
                        </Title>
                    </div>
                    {/* CONTENT */}
                    <div className='w-3/4 mx-auto'>
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    language === 'pl'
                                        ? items.pl_description
                                        : items.en_description,
                            }}
                        ></div>
                    </div>

                    {/* MODAL */}
                    <div className='w-3/4 mx-auto flex justify-center items-center text-center my-5'>
                        <Modal
                            title={language === 'pl' ? 'Galeria' : 'Gallery'}
                            content={items.gallery}
                        />
                    </div>
                </>
            );
        }
    }
}
