import React from 'react';
import cookies from 'react-cookies';
import axios from 'axios';
import Loading from '../../Atoms/Loading/Loading';
import './ReproductorsLink.scss';
import Title from '../../Atoms/Title/Title';
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";

export default class ReproductorsLink extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            error: null,
            isLoading: false,
        };
    }

    componentDidMount() {
        axios
            .get('https://api.shadowdog.eu/v1/reproductors')
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    toggleContent() {
        document
            .getElementById('ReproductorsContent')
            .classList.toggle('-left-full');
        document
            .getElementById('ReproductorsContent')
            .classList.toggle('left-0');
    }

    render() {
        const { items, isLoading, error } = this.state;
        const language = cookies.load('language');

        return (
            <>
                <div
                    className='fixed top-1/4 text-yellow-100 bg-black bg-opacity-30 w-8 text-xl text-center uppercase flex flex-col p-5 align-center items-center cursor-pointer RepButton'
                    onClick={this.toggleContent.bind(this)}
                    id='reproductorsButton'
                >
                    <div className='text'>
                        {language === 'pl'
                            ? 'R E P R O D U K T O R Y'
                            : 'R E P R O D U C T O R S'}
                    </div>
                </div>

                <div
                    className='w-full h-screen fixed top-0 z-50 -left-full transition-all duration-500'
                    id='ReproductorsContent'
                >
                    {/* CLOSE BUTTON */}
                    <div
                        className='w-10 h-10 absolute top-10 left-10 cursor-pointer'
                        onClick={this.toggleContent.bind(this)}
                    >
                        CLOSE
                    </div>

                    <div className='w-full py-20 max-h-screen overflow'>
                        {error ? error : ''}
                        {!isLoading ? <Loading /> : ''}
                        {items.map((e) => (
                            <div className='w-11/12 xl:w-3/4 flex flex-col mx-auto bg-black bg-opacity-20 rounded-xl my-4'>
                                {/* TITLE */}
                                <div>
                                    <Title size='27px'>{e.name}</Title>
                                </div>

                                {/* CONTENT */}
                                <div
                                    className='p-5 w-full'
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            language === 'pl'
                                                ? e.pl_description
                                                : e.en_description,
                                    }}
                                ></div>

                                {/* GALLERY */}
                                <div className='w-1/1  py-5'>
                                    <LightgalleryProvider className='z-max'>

                                            <div className='flex flex-wrap justify-center items-center'>
                                                {e.gallery.map(
                                                    (element, index) => (
                                                        <LightgalleryItem src={element.pathurl}>
                                                            <div className='m-2 cursor-pointer'>

                                                                    <img
                                                                        src={
                                                                            element.pathurl
                                                                        }
                                                                        alt=''
                                                                        className='h-32 w-auto rounded-md filter hover:brightness-125 transition-all m-0'
                                                                    />
                                                            </div>
                                                        </LightgalleryItem>
                                                    )
                                                )}
                                            </div>
                                    </LightgalleryProvider>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    }
}
