import Parahraph from 'components/Atoms/Paragraph/Paragraph';
import React from 'react';
import styled from 'styled-components';
import Logo from 'components/Atoms/Logo/Logo';
import facebookIcon from '../../../assets/images/facebookIcon.png';
import instagramIcon from '../../../assets/images/instagramIcon.png';
import youtubeIcon from '../../../assets/images/youtubeIcon.png';
import cookies from 'react-cookies';
import './ContactBar.scss';

const language = cookies.load('language');

const Text = styled.div`
    display: block;
    line-height: 30px;
    font-size: 18px;
    font-family: 'Abel', sans-serif;
`;

const ContactBar = () => (
    <div className='w-1/1 xl:w-3/4 max-w-screen-xl flex flex-col-reverse xl:flex-row items-center justify-center my-0 mx-auto xl:bg-contactLogo bg-cover bg-no-repeat bg-center overflow-hidden p-10 contactBar relative'>
        <div className='w-1/1 xl:w-1/2 flex flex-col p-3'>
            <Text>
                <Parahraph type='primary' marginB='30px'>
                    {language === 'pl'
                        ? 'ShadowDog - Hodowla zarejestrowana w ZKwP (FCI)'
                        : 'ShadowDog - Kennel registered in ZKwP (FCI)'}
                </Parahraph>
                <Parahraph>
                    Robert Gnyp & Beata Gnyp & Judyta Gnyp-Nosal
                </Parahraph>
                <Parahraph>
                    21-100 Lubartów, {language === 'pl' ? 'Polska' : 'Poland'}
                </Parahraph>
                <Parahraph>
                    tel.
                    <a href='tel://+48609170539' class='hover:text-red-800'>
                        (+48) 609-170-539{' '}
                    </a>
                    Beata (polish)
                </Parahraph>
                <Parahraph>
                    tel.
                    <a href='tel://+48667676606' className='hover:text-red-800'>
                        (+48) 667-676-606{' '}
                    </a>
                    Judyta (english & polish)
                </Parahraph>
                <Parahraph>
                    Email:{' '}
                    <a
                        href='mailto://shadowdog.fci@gmail.com'
                        className='hover:text-red-800'
                    >
                        shadowdog.fci@gmail.com
                    </a>{' '}
                    || Email:{' '}
                    <a
                        href='mailto://contact@shadowdog.eu'
                        className='hover:text-red-800'
                    >
                        contact@shadowdog.eu
                    </a>
                </Parahraph>
            </Text>
            {/* SOCIAL MEDIA */}
            <div className='flex p-2 justify-start items-center my-2'>
                <a href='https://www.facebook.com/judyta.gnyp' className='mx-2'>
                    <img
                        src={facebookIcon}
                        alt='facebook'
                        className='h-10 w-auto hover:scale-110 transform transition-transform duration-300'
                    />
                </a>
                <a
                    href='https://www.instagram.com/shadowdogfci/'
                    className='mx-2 '
                >
                    <img
                        src={instagramIcon}
                        alt='Instagram'
                        className='h-10 w-auto hover:scale-110 transform transition-transform duration-300'
                    />
                </a>
                <a
                    href='https://www.youtube.com/channel/UCp_QwuHWXzL1Ygqc1vDITxQ/featured'
                    className='mx-2'
                >
                    <img
                        src={youtubeIcon}
                        alt='YouTube'
                        className='h-10 w-auto hover:scale-110 transform transition-transform duration-300'
                    />
                </a>
            </div>
        </div>
        <div className='w-1/1 xl:w-1/2 flex justify-center items-center p-3'>
            <Logo width='200px' height='200px' />
        </div>
    </div>
);

export default ContactBar;
