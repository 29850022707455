import React from 'react';
import styled from 'styled-components';
import './Modal.scss';
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";

const StyledModal = styled.button`
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #202020;
    top: 0;
    transition: left 0.5s ease-out;
    z-index: 9999;
    cursor: default;
    overflow: auto;

    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: brown;
        border-radius: 10px;
    }
`;

const CloseButton = styled.div`
    width: 50px;
    height: 50px;
    position: absolute;
    left: 25px;
    top: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
`;

export default class Modal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpened: false,
        };

        this.switchModal = this.switchModal.bind(this);
    }

    switchModal() {
        this.setState((state) => ({
            modalOpened: !state.modalOpened,
        }));
    }

    render() {
        const { title, content } = this.props;

        return (
            <>
                <button
                    className='rounded-2xl p-3 border-2 border-modalButton text-xl text-modalButton hover:bg-modalButton hover:text-yellow-100 transition-colors no-underline mx-2 xl:min-w-modalButton'
                    onClick={this.switchModal}
                >
                    {title}
                </button>

                <StyledModal
                    className={this.state.modalOpened ? 'open z-9999' : 'close'}
                >
                    <CloseButton onClick={this.switchModal}>X</CloseButton>

                    {content.constructor === Array ? (
                        <LightgalleryProvider className='zupper'>

                                <div className='w-1/1 xl:w-3/4 py-20 flex flex-row justify-center items-start mx-auto flex-wrap text-center min-h-screen content-start z-max'>
                                    {content
                                        .filter((e) => e.category === 'gallery')
                                        .map((item, index) => (
                                            <LightgalleryItem src={item.pathurl}>
                                                <div className='m-3 cursor-pointer'>
                                                        <img
                                                            id={index}
                                                            src={item.pathurl}
                                                            alt=''
                                                            className='h-40 w-auto rounded-md filter hover:brightness-125 transition'
                                                        />
                                                </div>
                                            </LightgalleryItem>
                                        ))}
                                </div>
                        </LightgalleryProvider>
                        ) : (
                        <div
                        dangerouslySetInnerHTML={{__html: content}}
                    className='w-1/1 px-3 xl:px-28 py-28 mx-auto min-h-screen text-left contentText'
                />
                )}
            </StyledModal>
    </>
    )
        ;
    }
}
