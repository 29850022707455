import Title from 'components/Atoms/Title/Title';
import React from 'react';
import styled from 'styled-components';
import arrow from 'assets/images/arrow.svg';
import styles from './NewsElement.module.scss';
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import cookies from 'react-cookies';

const ShowMore = styled.div`
    width: 55px;
    height: 55px;
    border-radius: 50%;
    position: absolute;
    lefT: 50%;
    background-color: rgba(0, 0, 0, 1);
    background-image: url(${arrow});
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 900;
    cursor: pointer;
    border: 7px solid #202020;
    transform: translate(-50%, -50%);
`;

export default class NewsElement extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            showMore: false
        }
    }

    handleClick = () => {
        this.setState({
            showMore: !this.state.showMore
        })
    }

    render(){
        const { title, gallery, content } = this.props;
        const regex = new RegExp("/(<([^>]+)>)/ig");
        const contentLength = content.replace(regex, '').length;
        const language = cookies.load('language');

        return(
            <div className="w-11/12 xl:w-4/6 max-w-screen-xl mt-12 mb-8 mx-auto bg-black bg-opacity-10 rounded-xl relative" id={title}>
                <Title size="30px">{title}</Title>
                <div className="w-1/1 flex flex-col justify-center align-center m-auto">
                    <div className="w-1/1 p-8 overflow-hidden block break-words text-left">
                        {contentLength > 800 && this.state.showMore === false
                        ? 
                            <div className={styles.content} dangerouslySetInnerHTML={{__html: content.substring(0, 725)+'...'}}></div> 
                        : 
                            <div dangerouslySetInnerHTML={{__html: content}}></div>
                        }
                    </div>

                    <div className="w-full py-5 flex flex-row justify-center flex-wrap items-center">
                        <LightgalleryProvider>

                                {gallery.map((element, index) => (

                                    <LightgalleryItem src={element.pathurl}>
                                        <div className="flex flex-wrap justify-center items-center flex-row m-2 h-32">
                                            <img src={element.pathurl} alt=""
                                                 className="h-full w-auto cursor-pointer rounded-md filter hover:brightness-125 transition-all m-0"/>
                                        </div>

                                    </LightgalleryItem>

                                    ))}
                        </LightgalleryProvider>

                    </div>

                </div>

                {contentLength > 800 ? <ShowMore className={this.state.showMore === true ? styles.open : styles.close}
                                                 onClick={this.handleClick}/> : ''}
            </div>
        )
    }
}
