import NewsElement from 'components/Molecules/NewsElement/NewsElement';
import React from 'react';
import axios from 'axios';
import cookies from 'react-cookies';
import Loading from 'components/Atoms/Loading/Loading';
import Title from '../../Atoms/Title/Title';

export default class GetNewsElements extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            items: [],
            years: [],
            selectedYear: new Date().getFullYear(),
        };
    }

    componentDidMount() {
        this.getNews(this.state.selectedYear);
    }

    getNews(year = new Date().getFullYear()) {
        this.setState({
            isLoading: false,
        });

        window.scrollTo({ top: 0, behavior: 'smooth' });

        // Pobierz wszystkie newsy, aby wyciągnąć lata
        axios
            .get(`https://api.shadowdog.eu/v1/news`, {
                headers: { 'Content-Type': 'application/json' },
            })
            .then((res) => {
                const allNewsItems = res.data;
                const years = this.extractYears(allNewsItems);

                // Następnie pobierz newsy tylko dla wybranego roku
                return axios.get(`https://api.shadowdog.eu/v1/news?_year=${year}`, {
                    headers: { 'Content-Type': 'application/json' },
                }).then((res) => {
                    const newsItems = res.data;
                    this.setState({
                        isLoading: true,
                        items: newsItems,
                        years: years,
                        selectedYear: year, // Ustaw domyślny rok
                    });
                });
            })
            .catch((error) => {
                console.error('Error fetching news:', error);
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    extractYears(newsItems) {
        const yearsSet = new Set();
        newsItems.forEach((item) => {
            if (item.createdAt) { // Upewnij się, że data istnieje
                const year = new Date(item.createdAt).getFullYear();
                if (!isNaN(year)) { // Sprawdź, czy to faktycznie rok
                    yearsSet.add(year);
                }
            }
        });
        return Array.from(yearsSet).sort((a, b) => b - a); // Sortuj malejąco
    }

    filterNewsByYear(year) {
        this.setState({ selectedYear: year }, () => {
            this.getNews(year); // Pobierz newsy dla wybranego roku
        });
    }

    render() {
        const { error, isLoading, items, years, selectedYear } = this.state;
        const language = cookies.load('language');

        if (error) {
            return <span>Błąd: {error}</span>;
        } else if (!isLoading) {
            return <Loading />;
        } else {



            return (
                <>
                    {items
                        .filter((element) => element.isGlobal === 0)
                        .map((item) => (
                            <NewsElement
                                key={item.id}
                                title={
                                    language === 'pl'
                                        ? item.pl_title
                                        : item.en_title
                                }
                                content={
                                    language === 'pl'
                                        ? item.pl_description
                                        : item.en_description
                                }
                                gallery={
                                    item.gallery.length ? item.gallery : []
                                }
                            />
                        ))}

                    <div className='w-full xl:w-3/4 px-5 py-3 mx-auto'>
                        <Title>
                            {language === 'pl' ? 'Archiwum' : 'Archive'}
                        </Title>
                        <div className='flex flex-col w-full justify-center items-center'>
                            {years.map((year) => (
                                <button
                                    key={year}
                                    className={`w-1/12 bg-black bg-opacity-20 rounded-xl px-3 py-1 my-2 transform hover:scale-110 duration-300 ${
                                        selectedYear === year
                                            ? 'bg-blue-500 text-white'
                                            : ''
                                    }`}
                                    onClick={() => this.filterNewsByYear(year)}
                                >
                                    {year}
                                </button>
                            ))}
                            {/*<button*/}
                            {/*    className='w-1/12 bg-black bg-opacity-20 rounded-xl px-3 py-1 my-2 transform hover:scale-110 duration-300'*/}
                            {/*    onClick={() => this.getNews(2022)}*/}
                            {/*>*/}
                            {/*    2022*/}
                            {/*</button>*/}
                            {/*<button*/}
                            {/*    className='w-1/12 bg-black bg-opacity-20 rounded-xl px-3 py-1 my-2 transform hover:scale-110 duration-300'*/}
                            {/*    onClick={() => this.getNews(2021)}*/}
                            {/*>*/}
                            {/*    2021*/}
                            {/*</button>*/}
                            {/*<button*/}
                            {/*    className='w-1/12 bg-black bg-opacity-20 rounded-xl px-3 py-1 my-2 transform hover:scale-110 duration-300'*/}
                            {/*    onClick={() => this.getNews(2020)}*/}
                            {/*>*/}
                            {/*    2020*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </>
            );
        }
    }
}
