import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import Header from 'components/Organisms/Header/Header';

import NewsView from 'views/NewsView/NewsView';
import DogsView from 'views/DogsView/DogsView';
import LittersView from 'views/LittersView/LittersView';
import AboutusView from 'views/AboutusView/AboutusView';
import BreedView from 'views/BreedView/BreedView';
import ContactView from 'views/ContactView/ContactView';
import MainPage from 'views/MainPage/MainPage';
import Footer from 'components/Organisms/Footer/Footer';
import DogsDetailsView from 'views/DogsDetailsView/DogsDetailsView';
import LitterDetailsView from 'views/LitterDetailsView/LitterDetailsView';
import 'views/Root/GlobalStyles.module.scss';
import "lightgallery.js/dist/css/lightgallery.css";


const Root = ()=> {
    return(
        <Router>
            <Header />

            <Switch>
                <Route exact path="/" render={() => <Redirect to="/home" />} />
                <Route path="/home" component={MainPage} />
                <Route path="/news" component={NewsView} />
                <Route exact path="/dogs" component={DogsView} />
                <Route path="/dogs/:value" component={DogsDetailsView} />
                <Route exact path="/litters" component={LittersView} />
                <Route path="/litters/:value" component={LitterDetailsView} />
                <Route path="/about-us" component={AboutusView} />
                <Route path="/breed" component={BreedView} />
                <Route path="/contact" component={ContactView} />
            </Switch>

            <Footer />
        </Router>
    );
};

export default Root;