import React from 'react';
import axios from 'axios';
import Title from 'components/Atoms/Title/Title';
import LitterElement from 'components/Molecules/LitterElement/LitterElement';
import cookies from 'react-cookies';
import Loading from 'components/Atoms/Loading/Loading';

export default class GetDogsElements extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            items: [],
        };
    }

    componentDidMount() {
        axios
            .get('https://api.shadowdog.eu/v1/litters')
            .then((res) => {
                this.setState({
                    isLoading: true,
                    items: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { isLoading, error, items } = this.state;
        const language = cookies.load('language');

        if (error) {
            return <span>Błąd: {error}</span>;
        } else if (!isLoading) {
            return <Loading />;
        } else {
            return (
                <div className='flex flex-col w-4/5 my-0 mx-auto'>
                    <Title size='30px'>
                        {language === 'pl'
                            ? 'Setery Irlandzkie Czerwono-Białe'
                            : 'Irish Red And White Setters'}
                    </Title>
                    <div className='w-1/1 p-3 text-center flex justify-center items-center flex-wrap'>
                        {items
                            .filter((element) => element.breed === 'IRWS')
                            .map((litter) => (
                                <LitterElement
                                    key={litter.id}
                                    id={litter.id}
                                    title={
                                        language === 'pl'
                                            ? litter.pl_title
                                            : litter.en_title
                                    }
                                    birth={litter.birthDate + 1}
                                    gallery={
                                        litter.gallery.length
                                            ? litter.gallery
                                            : null
                                    }
                                />
                            ))}
                    </div>
                </div>
            );
        }
    }
}
